<!--
 * @Description: 首页
 * @Author: Chp
 * @Date: 2021-03-23 15:31:59
 * @LastEditTime: 2021-06-17 16:38:19
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="sljr-index">
    <!-- 头部轮播图 -->
    <div class="top-swiper">
      <swiper :options="swiperOption" ref="topSwiper">
        <!-- slides -->
        <swiper-slide v-for="(item, index) in bannerList" :key="index">
          <a :href="item.contentSource" target="_blank">
            <img :src="item.contentUrl" alt="" />
          </a>
        </swiper-slide>
        <!-- Optional controls -->
        <div class="swiper-pagination" slot="pagination"></div>
        <!-- <div class="swiper-button-prev" slot="button-prev"></div> -->
        <!-- <div class="swiper-button-next" slot="button-next"></div> -->
        <!-- <div class="swiper-scrollbar" slot="scrollbar"></div> -->
      </swiper>
    </div>
    <!-- 新闻动态 -->
    <div class="news">
      <div class="part-title">新闻动态</div>
      <div class="go-more">
        <span><router-link to="/news">查看更多></router-link></span>
      </div>
      <div class="news-swiper">
        <swiper :options="newsSwiperOption" ref="newsSwiper">
          <!-- slides -->
          <swiper-slide v-for="(item, index) in newsList" :key="index">
            <div class="item" @click="goNewsDetail(item)">
              <img class="cover" :src="item.contentUrl" />
              <h3>{{ item.contentTitle }}</h3>
              <p>{{ item.contentDatetime.substr(0, 10) }}</p>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="swiper-button-prev swiper-button-prev1"
          slot="button-prev"
        ></div>
        <div
          class="swiper-button-next swiper-button-next1"
          slot="button-next"
        ></div>
      </div>
    </div>
    <!-- 公益项目 -->
    <div class="public-project">
      <div class="part-title">公益项目</div>
      <div class="go-more">
        <router-link to="/pubilcWelfare">查看更多></router-link>
      </div>
      <div class="project-swiper">
        <swiper :options="proSwiperOption" ref="proSwiper">
          <!-- slides -->
          <swiper-slide v-for="(item, index) in welfareList" :key="index">
            <div class="item">
              <div class="pic">
                <img
                  @click="goPublictItem(item.id)"
                  class="cover"
                  :src="item.categoryImg"
                  alt="封面图"
                />
                <div class="index">
                  <span>{{ activeSlide + 1 }}</span
                  >/{{ welfareList.length }}
                </div>
              </div>
              <div class="info">
                <img
                  class="logo"
                  src="../../assets/images/logo_02.png"
                  alt="logo"
                />
                <div class="title" @click="goPublictItem(item.id)">
                  {{ item.categoryTitle }}
                </div>
                <div class="intro">{{ item.categoryKeyword }}</div>
                <div class="btn" @click="goPublictItem(item.id)">查看详情</div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="swiper-button-prev swiper-button-prev2"
          slot="button-prev"
        ></div>
        <div
          class="swiper-button-next swiper-button-next2"
          slot="button-next"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>

let vm = null
export default {
  name: 'Index',
  components: {
  },
  created () {
    vm = this
    this.getBanner();
    this.getNewsList();
    // this.getPublicWelfare();
  },
  computed: {
    swiper () {
      return this.$refs.topSwiper.$swiper
    },
    newsSwiper () {
      return this.$refs.newsSwiper.$swiper
    },
    proSwiper () {
      return this.$refs.proSwiper.$swiper
    },
    welfareList () {
      return this.$store.state.publicWelfareList  // 取出公益项目
    }
  },
  data () {
    return {
      activeSlide: 0, //公益项目下标
      swiperOption: {
        // 参数选项,显示小点
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        //循环
        loop: false,
        //每张播放时长3秒，自动播放
        autoplay: false,
        //滑动速度
        speed: 1000,
        // delay:1000
      },
      newsSwiperOption: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 24,
        navigation: {
          nextEl: '.swiper-button-next1',
          prevEl: '.swiper-button-prev1',
        }
      },
      proSwiperOption: {
        // autoplay: true,
        navigation: {
          nextEl: '.swiper-button-next2',
          prevEl: '.swiper-button-prev2',
        },
        on: {
          slideChange () {
            vm.activeSlide = this.activeIndex
          }
        }
      },
      bannerList: [], //banner
      newsList: [],//新闻动态
      //   welfareList: []//公益项目
    }
  },
  methods: {

    /**
     * @description: 获取banner
     * @param  无
     * @return {*}
     */
    getBanner () {
      let p = {
        categoryId: '1377185864214577154',
      }
      this.$api.getContentList(p).then(res => {
        this.bannerList = res.data.data.rows;
      })
    },

    /**
     * @description: 新闻动态
     * @param  {categoryId,categoryType,pageNo,pageSize}
     * @return {*}
     */
    getNewsList () {
      let params = {
        categoryId: '1376791966871883777',
        categoryType: '1',
        pageNo: '1',
        pageSize: '8'
      }
      this.$api.getNews(params).then((res) => {
        this.newsList = res.data.data.rows
      })
    },
    /**
     * @description: 跳转动态详情
     */
    goNewsDetail (item) {
      let { categoryId, id } = item;
      switch (categoryId) {
        case '1376792241594601473'://项目动态
          this.$router.push({ path: '/news/project/proArticle', query: { id: id } })
          break;
        case '1376792129237585921'://机构动态
          this.$router.push({ path: '/news/mechanism/mecArticle', query: { id: id } })
          break;
        case '1376792888784097281'://活动图集
          this.$router.push({ path: '/news/album/albumArticle', query: { id: id } })
          break
      }
    },
    /**
     * @description: 跳转公益项目
     */
    goPublictItem (id) {
      this.$router.push({ path: '/pubilcWelfare/publictItem', query: { id: id } })
    },
  }
}
</script>

<style scoped lang='scss'>
.top-swiper {
  .swiper-container {
    max-width: 100%;
    max-height: 788px;
    img {
      width: 100%;
      display: block;
      margin: 0 auto;
      //   height: 100%;
    }
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    width: initial;
    border: 1px solid rgba(255, 255, 255, 0.6);
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    border-radius: 50px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-items: center;
    box-sizing: content-box;
    /deep/.swiper-pagination-bullet {
      width: 14px;
      height: 14px;
      background: rgba(255, 255, 255, 0.6);
      opacity: 1;
    }
    /deep/.swiper-pagination-bullet-active {
      background: rgba(255, 255, 255, 0.9);
    }
  }
}
.news {
  margin-top: 70px;
  margin-bottom: 100px;
  overflow-x: hidden;
  .news-swiper {
    margin: 8px auto 0 auto;
    width: 1200px;
    position: relative;
    .item {
      width: 282px;
      .cover {
        width: 100%;
        height: 172px;
        background: #fbfbfb;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      h3 {
        margin-top: 6px;
        font-weight: 500;
        cursor: pointer;
        height: 55px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        &:hover {
          color: $main-color;
        }
      }
      p {
        font-size: 12px;
        color: #bdbdbd;
        margin-top: 12px;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      top: 0;
      transform: translateY(0);
    }
  }
}
.public-project {
  overflow-x: hidden;
  .project-swiper {
    margin: 8px auto 0 auto;
    width: 1200px;
    position: relative;
    .item {
      height: 484px;
      background: #fbfbfb;
      display: flex;
      .pic {
        position: relative;
        width: 794px;
        height: 484px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
        .cover {
          width: 100%;
          height: 100%;
          display: block;
        }
        .index {
          position: absolute;
          bottom: 5px;
          right: 10px;
          font-size: 14px;
          color: #999999;
          padding: 2px 15px;
          background: rgba(255, 255, 255, 0.4);
          border-radius: 15px;
          span {
            font-size: 25px;
            color: $main-color;
            font-weight: 500;
            line-height: 26px;
          }
        }
      }
      .info {
        padding: 40px;
        flex: 1;
        .logo {
          width: 55px;
          height: 58px;
        }
        .title {
          font-size: 26px;
          color: #333;
          font-weight: 500;
          margin-bottom: 35px;
          margin-top: 40px;
          cursor: pointer;
          &:hover {
            color: $main-color;
          }
        }
        .intro {
          color: #666666;
          line-height: 26px;
          height: 125px;
          //   width: 100%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
          overflow: hidden;
        }
        .btn {
          width: 142px;
          height: 40px;
          background: #e50113;
          border-radius: 4px;
          text-align: center;
          line-height: 40px;
          color: #fff;
          margin-top: 60px;
          cursor: pointer;
        }
      }
    }
  }
}
.part-title {
  font-size: 30px;
  color: #333333;
  font-weight: 500;
  text-align: center;
  &::after {
    content: "";
    width: 40px;
    height: 6px;
    background: $main-color;
    display: block;
    margin: 15px auto 0 auto;
  }
}
// .more {
//   width: 1200px;
//   margin: 0 auto;
//   text-align: right;
//   font-size: 16px;
//   color: #666666;
//   margin-top: 22px;
//   cursor: pointer;
//   span:hover {
//     color: $main-color;
//   }
// }
</style>